const moment = require('moment');

function urlify(str) {
  return str.replace(/[^A-Za-z0-9]/g, "-").toLowerCase()
}

function slug(episode) {
  return '/episode/'+episode.id+'/'+urlify(episode.title)
}

function permalink(episode) {
  return window.location.origin+slug(episode)
}

export {
  permalink, urlify, slug
}