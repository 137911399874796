<template>
  <div class="content" v-if="message">
    <div class="message" :class="color ? color : ''">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    message: String,
    color: String
  }
}
</script>

<style lang="scss" scoped>
.message {
  position: relative;
  backdrop-filter: blur(12px);
  background-color: var(--bg-color-1);
  border: 1px solid var(--font-color-3);
  padding: 1rem;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: .5rem;
}

@supports not (backdrop-filter: blur(12px)) {
  .message {
    background-color: var(--bg-color);
  }
}

.green, .yellow, .red {
  color: var(--font-color);
  backdrop-filter: none;
}

.green {
  background-color: var(--primary-color);
}

.yellow {
  background-color: var(--yellow);
}

.red {
  background-color: var(--red);
}
</style>