<template>
  <div id="app" :class="settings ? settings.dark ? 'dark' : 'light' : 'no-s'">
    <div class="app-container">
      <Nav ref="nav" :session="session" :player="player" @destroySession="destroySession" @hideMobilePlayer="hideMobilePlayer" />
      <div class="messages">
        <Message v-for="message in messages" :message="message.message" :color="message.color" :key="message.id" />
      </div>
      <div class="actual-app" :class="session ? 'valid-session' : 'invalid-session'">
        <UpdateMessage v-if="isUpdateAvailable" />
        <transition name="fade" mode="out-in">
          <router-view
            @createSession="updateSession"
            @destroySession="destroySession"
            @deleteSession="deleteSession"
            @validateSession="validateSession"
            @play="play"
            @queue="queue"
            @setSettings="setSettings"
            @message="message"
            @updateMasterFeed="updateMasterFeed"
            @filter="filter"
            @getFilter="getFilter"
            @getFeed="getFeed"
            @getPersonalFeed="getPersonalFeed"
            @refreshFeed="refreshFeed"
            @refreshPersonalFeed="refreshPersonalFeed"
            :filters="filters"
            :selectedFilters="selectedFilters"
            :masterFeed="masterFeed"
            :personalFeed="personalFeed"
            :player="player"
            :playerInstance="$refs.player"
            :moment="moment" :user="user"
            :settings="settings"
            :session="session"
            :isPlaying="isPlaying"
            :fetchingFeed="fetchingFeed"
            :fetchingPersonalFeed="fetchingPersonalFeed"
            :finished="finished"
            :finishedPersonal="finishedPersonal"
            :listens="listens"
            :messageLog="messageLog"
            @getListen="getListen"
            @updateListen="updateListen"
          />
        </transition>
      </div>
      <Player ref="player" :session="session" :player="player" :settings="settings" :moment="moment" @playPause="playPause" @updateListen="updateListen"></Player>
      <div class="cookie-consent" v-if="!acceptedCookies">
        <span class="text">Denne side logger din adgang og bruger kun cookies, der er nødvendige inden for vores gyldige interesse. For mere information og dine rettigheder vedrørende databeskyttelse, se <router-link to="/privacy">privatlivspolitik</router-link>.</span>
        <span class="button-container"><a href="javascript:void(0)" class="button alt" v-on:click="cookieConsent()">Okay</a></span>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
const moment = require('moment');
import 'normalize.css'
import Player from '@/components/Player.vue'
import Nav from '@/components/Nav.vue'
import Message from '@/components/Message.vue'
import UpdateMessage from '@/components/UpdateMessage.vue'

export default {
  name: 'App',
  components: {
    Player,
    Nav,
    Message,
    UpdateMessage
  },
  data: function() {
    return {
      isUpdateAvailable: false,
      user: null,
      userID: null,
      session: this.getCookie('session'),
      acceptedCookies: true, //this.getCookie('ac'),
      settings: null,
      player: {},
      moment: moment,
      isPlaying: false,
      messages: [],
      messageLog: [],
      masterFeed: [],
      personalFeed: [],
      filters: null,
      selectedFilters: {
        leagues: [],
        superliga_clubs: [],
        formats: [],
        special: []
      },
      page: 0,
      personalPage: 0,
      finished: false,
      finishedPersonal: false,
      maxCount: 0,
      fetchingFeed: false,
      fetchingPersonalFeed: false,
      listens: null,
      lf: this.getCookie('lf')
    }
  },
  methods: {
    message: function(message, color=null) {
      let messageObject = {
        message: message,
        color: color,
        id: Date.now()
      }
      this.messages.push(messageObject)
      this.messageLog.push(messageObject)
      setTimeout(() => {
        this.messages.shift()
      }, 2500)
    },
    updateMasterFeed: function(feed) {
      this.masterFeed=feed
    },
    updatePersonalFeed: function(feed) {
      this.personalFeed=feed
    },
    queue: function(episode) {
      if(this.$refs.player) {
        this.$refs.player.addToQueue(episode)
      }
    },
    playPause: function(x) {
      this.isPlaying = !x
    },
    refreshCookies: function() {
      if (this.session) { this.setCookie('session', this.getCookie('session'), 30) }
      if (this.acceptedCookies) { this.setCookie('ac', this.getCookie('ac'), 365) }
      if (this.settings) { this.setCookie('so', this.getCookie('so'), 365) }
    },
    refreshFeed: function() {
      this.finished = false
      this.masterFeed = []
      this.page = 0
      this.getFeed()
    },
    refreshPersonalFeed: function() {
      this.finishedPersonal = false
      this.personalFeed = []
      this.personalPage = 0
      this.getPersonalFeed()
    },
    getFeed: function() {
      if (this.finished) { return }
      this.fetchingFeed = true;
      axios.get(process.env.VUE_APP_API+'/feed/'+this.session+'/'+this.page)
      .then((res) => {
        if (res.data) {
          if (res.data.data.length == 0 || res.data.data.length < this.maxCount) {
            this.finished = true;
          }
          for (let i = 0; i < res.data.data.length; i++) {
            if(!this.masterFeed.includes(res.data.data[i])) {
              this.masterFeed.push(res.data.data[i])
            }
            if (this.page==0) {
              this.maxCount = res.data.data.length
            }
          }
          this.page++
          this.fetchingFeed = false;
        } else {
          this.error='404'
          this.message('Ingen data i feed', 'red')
          this.fetchingFeed = false;
        }
      })
      .catch((error) => {
        console.log(error)
        this.error = error;
        this.message('Kunne ikke hente feed', 'red')
        this.fetchingFeed = false;
      });
    },
    getPersonalFeed: function() {
      if (this.finishedPersonal) { return }
      this.fetchingPersonalFeed = true;
      axios.get(process.env.VUE_APP_API+'/personal/'+this.session+'/'+this.personalPage)
      .then((res) => {
        if (res.data) {
          if (res.data.err) {
            this.fetchingPersonalFeed = false;
          } else {
            if (res.data.data.length == 0 || res.data.data.length < this.maxCount) {
              this.finishedPersonal = true;
            }
            for (let i = 0; i < res.data.data.length; i++) {
              if(!this.personalFeed.includes(res.data.data[i])) {
                this.personalFeed.push(res.data.data[i])
              }
              if (this.personalPage==0) {
                this.maxCount = res.data.data.length
              }
            }
            this.personalPage++
            this.fetchingPersonalFeed = false;
          }
        } else {
          this.error='404'
          this.message('Ingen data i feed', 'red')
          this.fetchingPersonalFeed = false;
        }
      })
      .catch((error) => {
        console.log(error)
        this.error = error;
        this.message('Kunne ikke hente feed', 'red')
        this.fetchingPersonalFeed = false;
      });
    },
    getFilter: function() {
      axios.get(process.env.VUE_APP_API+'/filters/'+this.session)
      .then((res) => {
        if (res.data) {
          var filters = res.data
          this.filters = {
            leagues: filters.leagues,
            superliga_clubs: filters.superliga_clubs,
            formats: filters.formats,
            special: filters.special
          }
          if (filters.selected) {
            if (!filters.selected.special) {
              filters.selected.special = []
            }
            this.selectedFilters = filters.selected
          }
        } else {
          this.error='404'
        }
      })
      .catch((error) => {
        this.error = error;
      });
    },
    filter: function(type, option) {
      if (this.selectedFilters[type].includes(option)) {
        var index = this.selectedFilters[type].indexOf(option)
        this.selectedFilters[type].splice(index, 1)
        if (option=='superliga') {
          this.selectedFilters.superliga_clubs = []
        }
      } else {
        this.selectedFilters[type].push(option)
      }
    },
    play: function(episode) {
      this.player = {
        episode: episode,
        play: true
      }
      this.$nextTick(function() {
        this.$refs.player.play()
        let l = this.getListen(episode.id)
        if (l) {
          if (l.time>=15) {
            this.$refs.player.setTime(l.time)
          }
        }
      })
    },
    playTime: function(time, episode, noplay=false) {
      this.player = {
        episode: episode,
        play: true
      }
      this.$nextTick(function() {
        if(!noplay){this.$refs.player.play(true)}
        this.$refs.player.setTime(time)
      })
    },
    keybind: function(e) {
      let keybinds = {
        "j": ()=>{this.$refs.player.rewind()},
        "k": ()=>{this.$refs.player.play()},
        "l": ()=>{this.$refs.player.forward()},
        "m": ()=>{this.$refs.player.mute()},
        "+": ()=>{this.$refs.player.tickVolume(+0.1)},
        "-": ()=>{this.$refs.player.tickVolume(-0.1)},
        "d": ()=>{var obj = this.settings;!obj?obj={}:null;obj.dark?obj.dark=!obj.dark:obj.dark=true;this.setSettings(obj)},
        "s": ()=>{var obj = this.settings;!obj?obj={}:null;obj.small_episode?obj.small_episode=!obj.small_episode:obj.small_episode=true;this.setSettings(obj)}
      }

      var activeElement = document.activeElement
      var inputs = ['input', 'select', 'button', 'textarea', 'a']

      if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) == -1) {
        if(keybinds[e.key]){keybinds[e.key]()}
      }
      
    },
    reloadSettings: function() {
      this.settings = JSON.parse(this.getCookie('so'))
    },
    setSettings: function(obj) {
      this.setCookie('so', JSON.stringify(obj), 365)
      this.reloadSettings()
    },
    updateSession: function(token, play=false) {
      this.session = token
      this.validateSession(play)
    },
    deleteSession: function(session, cb=(err, res)=>{}) {
      axios.delete(process.env.VUE_APP_API+'/session/'+session)
      .then((res)=>{
        if (res.data.err) {
          this.$emit('message', 'Kunne ikke slette session: '+session, 'red')
        } else {
          cb(null, res)
          this.validateSession()
        }
      })
      .catch((err)=>{
        console.log(err)
        this.$emit('message', 'Kunne ikke slette session: '+session, 'red')
      })
    },
    destroySession: function() {
      this.deleteSession(this.session, (err, res)=>{
        if (res) {
          this.eraseCookie('session')
          this.updateSession(null)
        }
      })
    },
    cookieConsent: function() {
      this.setCookie('ac', true, 365)
      this.acceptedCookies = this.getCookie('ac')
    },
    setCookie: (name,value,days) => {
      var expires = "";
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },
    getCookie: (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    eraseCookie: (name) => {
      document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    humanize: function(str) {
      var i, frags = str.split(/[_-]+/);
      for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    },
    loginPush: function() {
      let slug = window.location.href.replace(window.location.origin,'')
      if (!slug.startsWith('/login') && !slug.startsWith('/register') && !slug.startsWith('/news') && !slug.startsWith('/about') && !slug.startsWith('/privacy') && !slug.startsWith('/terms') && !slug.startsWith('/confirm') && !slug.startsWith('/forgot')) {
        let r = ''
        if (slug!='/'&&slug!='/app'&&slug!='/feed') {r='?r='+slug}
        this.$router.push('login'+r)
      }
    },
    getListen: function(episode) {
      if (!this.listens) {return null}
      for (let i = 0; i < this.listens.length; i++) {
        if (this.listens[i].episode == episode) {
          return this.listens[i]
        }
      }
      return null
    },
    updateListen: function(episode, time) {
      if (time>15 && this.listens) {
        for (let i = 0; i < this.listens.length; i++) {
          if (this.listens[i].episode == episode.id) {
            this.listens[i].time = time
            return this.listens[i]
          }
        }
        this.listens.push({
          episode: episode.id,
          time: time,
          timestamp: Date.now(),
          user: null,
          _id: null
        })
        return this.listens[this.listens.length-1]
      }
    },
    validateSession: function(play=false) {
      if (this.session) {

        axios.get(process.env.VUE_APP_API+'/session/'+this.session)
        .then((res) => {
          if (res.data.session) {
            this.userID = res.data.session.user
            this.user = res.data.session.userObject
            let path = window.location.href.replace(window.location.origin,'')
            if (path=='/'||path=='/app') {
              if (this.lf==2) {
                this.$router.push('personal')
              } else {
                this.$router.push('feed')
              }
            }
            if (res.data.session.playstate && play) {

              var ps = res.data.session.playstate.playstate

              axios.get(process.env.VUE_APP_API+'/episode/'+ps.episode+'/'+this.session)
              .then((r2)=>{
                this.playTime(ps.time, r2.data.data, true)
              })

            }

            if (res.data.session.queue) {
              
              if (res.data.session.queue.queue && res.data.session.queue.episodes) {

                var order = {}

                for (let i = 0; i < res.data.session.queue.queue.length; i++) {
                  order[res.data.session.queue.queue[i]] = i
                }

                var correctOrder = []

                for (let i = 0; i < res.data.session.queue.episodes.length; i++) {
                  correctOrder[order[res.data.session.queue.episodes[i].id]] = res.data.session.queue.episodes[i]
                }

                this.$refs.player.setQueue(correctOrder, true)

              }

            }

            if (res.data.session.listens) {
              
              this.listens = res.data.session.listens

            }

          } else {
            this.message('Ugyldig session', 'yellow')
            this.session = null
            this.user = null
            this.userID = null
            this.eraseCookie('session')
            this.loginPush()
          }
        })
        .catch((error) => {
          this.error = error;
          console.log(error)
          this.message('Der opstod en ukendt fejl 😰')
        });
        
      } else {
        this.loginPush()
      }
    },
    fillSettings() {
      this.settings = JSON.parse(this.getCookie('so'))

      if (!this.settings) {
        var obj = {}
        obj.dark = true
        obj.small_episode = true
        obj.displayRemainingTime = false
        obj.interval = 15
        this.setSettings(obj)
      }

    },
    hideMobilePlayer() {
      if(this.$refs.player) {
        this.$refs.player.updateRoute()
      }
    },
    updateAvailable: function(event) {
      setTimeout(()=>{
        this.isUpdateAvailable = true
      },3000)
    }
  },
  created() {
    document.addEventListener('updateAvailable', this.updateAvailable, { once: true })

    document.addEventListener('offlineMode', ()=>{
      this.message('Ingen internet forbindelse')
    }, { once: true })
  },
  mounted: function() {

    this.validateSession(true)
    this.refreshCookies()

    setInterval(() => {
      this.validateSession()
      this.refreshCookies()
    }, 1000*60*15);

    console.log(
      "%cHalløj 👋",
      "color:#3ed471;font-family:neue-haas-grotesk-text, sans-serif;font-size:2rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );

    document.title = 'Mit Mediano'

    window.addEventListener('keyup', (e) => {
      this.keybind(e)
    });

    this.fillSettings()

    if (this.session) {
      //this.getFeed()
      //this.getFilter()
    }

  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        switch (to.path) {
          case '/feed':
            this.setCookie('lf', 1, 30)
            break
          case '/personal':
            this.setCookie('lf', 2, 30)
            break
          default:
            this.lf = this.getCookie('lf')
            break
        }
        if(this.$refs.nav) {
          this.$refs.nav.updateRoute()
        }
        this.hideMobilePlayer()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/main.scss';
@import '@/assets/vars.scss';

.app-container {
  position: relative;
}

.cookie-consent {
  background: var(--bg-color-1);
  backdrop-filter: blur(12px);
  position: fixed;
  bottom: 0;
  right: 0;
  border: 1px solid var(--font-color-3);
  padding: 1rem;
  margin: 2rem;
  border-radius: 3px;
  z-index: 999999999;
  max-width: 600px;
}

@supports not (backdrop-filter: blur(12px)) {
  .cookie-consent {
    background-color: var(--bg-color);
  }
}

.cookie-consent .text {
  margin-bottom: 1rem;
  display: block;
}
.cookie-consent .button-container {
  display: block;
}

.content {
  margin: auto;
  padding: 0 1rem;
  max-width: 720px;
}

.valid-session .content {
  margin-top: $nav-height-object-after;
}

.valid-session .space-for-player {
  margin-bottom: calc(#{$player-height} + 2rem);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}

.messages {
  position: fixed;
  z-index: 1;
  pointer-events: none;
  width: 100vw;
  text-align: center;
}

html, body { //idk if this is a good idea? @ludvig #TODO: test this #DONE: seems fine?
  overscroll-behavior-y: contain;
}
</style>