<template>
  <div class="player-wrapper" v-on:mouseup="seeking=false" v-on:mouseleave="seeking=false">
    <div class="player" v-if="player.episode && session" :class="[player.episode.podcast ? 'with-cover' : 'no-cover', 'audioSource_'+audioSource]">
      <div class="no-internet" v-if="errorMessage">
        {{errorMessageString()}}
      </div>
      <div class="info">
        <div class="icon">
          <router-link :to="'/podcast/'+player.episode.podcast.id" v-if="player.episode.podcast">
            <img :src="directusAsset(player.episode.podcast.cover_144)" alt="">
            <div class="on-hover"><ion-icon name="arrow-up"></ion-icon></div>
          </router-link>
        </div>
        <router-link :to="slug(player.episode)" class="incognito textPortion">
          <div class="title">
            <ion-icon name="logo-soundcloud" v-if="audioSource=='sc'"></ion-icon>
            {{player.episode.title}}
          </div>
          <div class="author">
            {{player.episode.podcast ? player.episode.podcast.name : player.episode.author}}
          </div>
        </router-link>
      </div>
      <audio ref="audio" :src="audioAsset(player.episode)" preload="auto" />
      <div class="controls">
        <div class="buttons">
          <button class="fake-button rewind" v-on:click="rewind()"><ion-icon name="reload"></ion-icon></button>
          <button class="fake-button play" v-on:click="play()"><ion-icon name="play" v-if="paused"></ion-icon><ion-icon name="pause" v-if="!paused"></ion-icon></button>
          <button class="fake-button forward" v-on:click="forward()"><ion-icon name="reload"></ion-icon></button>
        </div>

        <div class="time" v-on:click="showMobilePlayer=true">
          <div class="current">
            {{moment.utc(time*1000).format('HH:mm:ss')}}
          </div>
          <div class="progress" ref="progress" v-on:mousedown="(e)=>{seeking=true;scrub(e)}" v-on:mousemove="scrub" v-on:touchstart="(e)=>{seeking=true;scrub(e)}" v-on:touchmove="scrub">
            <div class="filled-wrapper">
              <div class="filled" :style="{ width: ( time / duration * 100 ) + '%' }"></div>
            </div>
            <div class="playhead" :style="{ left: 'calc(' + ( time / duration * 100 ) + '% - 4px)' }"></div>
          </div>
          <div class="max">
            {{settings.displayRemainingTime==true ? moment.utc((duration-time)*1000).format('-HH:mm:ss') : moment.utc(duration*1000).format('HH:mm:ss')}}
          </div>
        </div>

        <div class="right-buttons">

          <div class="volume-container">
            <a href="javascript:void(0)" class="mute alt" v-on:click="mute">
              <ion-icon name="volume-high" v-if="volume > 0"></ion-icon>
              <ion-icon name="volume-mute" v-else></ion-icon>
            </a>
            <div class="volume" ref="volume" v-on:mousedown="(e)=>{seeking=true;changeVolume(e)}" v-on:mousemove="changeVolume">
              <div class="filled" :style="{width: volume*100+'%'}"></div>
            </div>
          </div>

          <div class="speed" :class="speedOptionsOpen ? 'open' : 'closed'" v-click-outside="hideSpeedOptions">
            <div class="options monospace">
              <a class="alt" :class="playbackRate==2 ? 'activeSpeed' : ''" v-on:click="setSpeed(2)" href="javascript:void(0)">200%</a>
              <a class="alt" :class="playbackRate==1.5 ? 'activeSpeed' : ''" v-on:click="setSpeed(1.5)" href="javascript:void(0)">150%</a>
              <a class="alt" :class="playbackRate==1.25 ? 'activeSpeed' : ''" v-on:click="setSpeed(1.25)" href="javascript:void(0)">125%</a>
              <a class="alt" :class="playbackRate==1 ? 'activeSpeed' : ''" v-on:click="setSpeed(1)" href="javascript:void(0)">100%</a>
              <a class="alt" :class="playbackRate==0.75 ? 'activeSpeed' : ''" v-on:click="setSpeed(0.75)" href="javascript:void(0)">75%</a>
              <a class="alt" :class="playbackRate==0.5 ? 'activeSpeed' : ''" v-on:click="setSpeed(0.5)" href="javascript:void(0)">50%</a>
            </div>
            <a v-on:click="speedOptionsOpen=!speedOptionsOpen" href="javascript:void(0)" class="alt opener"><ion-icon name="speedometer"></ion-icon></a>
          </div>

        </div>

      </div>
    </div>

    <div class="mobile-full-player" :class="showMobilePlayer ? 'shown': 'hidden'" v-if="player.episode && session">
      <div class="inner">
        <div class="cover" v-if="player.episode.podcast"><img :src="directusAsset(player.episode.podcast.cover_1400)" alt=""></div>
        <div class="info">
          <div class="title">{{player.episode.title}}</div>
          <div class="author">{{player.episode.author}}</div>
        </div>

        <div class="controls">

          <div class="time">
            <div class="stamps">
              <div class="current">
                {{moment.utc(time*1000).format('HH:mm:ss')}}
              </div>
              <div class="max">
                {{settings.displayRemainingTime==true ? moment.utc((duration-time)*1000).format('-HH:mm:ss') : moment.utc(duration*1000).format('HH:mm:ss')}}
              </div>
            </div>
            <div class="progress" ref="mobileProgress" v-on:mousedown="(e)=>{seeking=true;mobileScrub(e)}" v-on:mousemove="mobileScrub" v-on:touchstart="(e)=>{seeking=true;mobileScrub(e)}" v-on:touchmove="mobileScrub">
              <div class="filled" :style="{ width: ( time / duration * 100 ) + '%' }"></div>
              <div class="playhead" :style="{ left: 'calc(' + ( time / duration * 100 ) + '% - 4px)' }"></div>
            </div>
          </div>

          <div class="buttons">
            <button class="fake-button rewind" v-on:click="rewind()"><ion-icon name="reload"></ion-icon></button>
            <button class="fake-button play" v-on:click="play()"><ion-icon name="play-circle" v-if="paused"></ion-icon><ion-icon name="pause-circle" v-if="!paused"></ion-icon></button>
            <button class="fake-button forward" v-on:click="forward()"><ion-icon name="reload"></ion-icon></button>
          </div>

          <div class="lower-buttons">
            <div class="hide">
              <a class="alt" href="javascript:void(0)" v-on:click="showMobilePlayer=false"><ion-icon name="chevron-down"></ion-icon></a>
              <a class="alt" href="javascript:void(0)" v-on:click="share"><ion-icon name="link"></ion-icon></a>
            </div>
            <div class="speed" :class="speedOptionsOpenMobile ? 'open' : 'closed'" v-click-outside="hideSpeedOptionsMobile">
              <div class="options monospace">
                <a class="alt" :class="playbackRate==2 ? 'activeSpeed' : ''" v-on:click="setSpeedMobile(2)" href="javascript:void(0)">200%</a>
                <a class="alt" :class="playbackRate==1.5 ? 'activeSpeed' : ''" v-on:click="setSpeedMobile(1.5)" href="javascript:void(0)">150%</a>
                <a class="alt" :class="playbackRate==1.25 ? 'activeSpeed' : ''" v-on:click="setSpeedMobile(1.25)" href="javascript:void(0)">125%</a>
                <a class="alt" :class="playbackRate==1 ? 'activeSpeed' : ''" v-on:click="setSpeedMobile(1)" href="javascript:void(0)">100%</a>
                <a class="alt" :class="playbackRate==0.75 ? 'activeSpeed' : ''" v-on:click="setSpeedMobile(0.75)" href="javascript:void(0)">75%</a>
                <a class="alt" :class="playbackRate==0.5 ? 'activeSpeed' : ''" v-on:click="setSpeedMobile(0.5)" href="javascript:void(0)">50%</a>
              </div>
              <router-link class="alt shownotes" :to="slug(this.player.episode)"><ion-icon name="arrow-up" style="transform:rotate(45deg);"></ion-icon></router-link>
              <a v-on:click="speedOptionsOpenMobile=!speedOptionsOpenMobile" href="javascript:void(0)" class="alt opener"><ion-icon name="speedometer"></ion-icon></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import ClickOutside from 'vue-click-outside'
import { permalink, slug, urlify } from '@/assets/permalink.js'

export default {
  name: 'Player',
  data: function() {
    return {
      paused: true,
      time: 0,
      lastTime: 0,
      duration: 0,
      playbackRate: 1,
      volume: 1,
      volumeBeforeMute: 1,
      speedOptionsOpen: false,
      speedOptionsOpenMobile: false,
      showMobilePlayer: false,
      updateDurationOnNextTimeUpdate: false,
      errorMessage: false,
      failedPlays: 0,
      seeking: false,
      queue: []
    }
  },
  directives: {
    ClickOutside
  },
  props: {
    player: Object,
    moment: Function,
    session: String,
    settings: Object
  },
  methods: {
    slug: (x)=>{return slug(x)},
    urlify: (str)=>{return urlify(str)},
    hideSpeedOptions: function() {
      this.speedOptionsOpen = false
    },
    hideSpeedOptionsMobile: function() {
      this.speedOptionsOpenMobile = false
    },
    setSpeed: function(val) {
      this.$refs.audio.playbackRate = val
      this.playbackRate = val
      this.$parent.setCookie('playerPlaybackRate', val, 30)
      this.hideSpeedOptions()
    },
    setSpeedMobile: function(val) {
      this.$refs.audio.playbackRate = val
      this.playbackRate = val
      this.$parent.setCookie('playerPlaybackRate', val, 30)
      this.hideSpeedOptionsMobile()
    },
    directusAsset: function(id) {
      return 'https://directus.mediano.media/assets/' + id
    },
    audioAsset: function(episode) {
      if (episode.audio) {
        return this.directusAsset(episode.audio)
      } else if (episode.cdn_audio) {
        return episode.cdn_audio
      } else {
        return episode.sc_audio
      }
    },
    play: function(alwaysPlay=false) {
      if (this.$refs.audio) {
        if(this.errorMessage == 2 || this.failedPlays > 0) { // if play failed before and play event is called, reload audio
          this.reloadAudio()
        }
        var title = this.player.episode.title + ' — Mit Mediano'
        if (this.$refs.audio.paused || alwaysPlay) {
          this.paused = false
          this.$refs.audio.play().catch((err)=>{})
        } else {
          this.paused = true
          this.$refs.audio.pause()
          title = '⏸️ ' + title
        }
        if (Math.abs(this.$refs.audio.currentTime - this.time) > 1) { // if time is not synced, seek to time, Vue data is source of truth
          this.setTime(this.time)
        }
        document.title = title
        this.$refs.audio.volume = this.volume
        this.$refs.audio.playbackRate = this.playbackRate
        this.updateDuration()
        this.updateTime()
        this.updateMediaSession()
        this.$emit('playPause', this.paused)
      }
    },
    reloadAudio: function() {
      this.$refs.audio.load()
      this.setTime(this.time)
    },
    share: function() {
      if (navigator.share) {
        navigator.share({
          title: this.player.episode.title,
          text: 'Lyt til ' + this.player.episode.title,
          url: permalink(this.player.episode)
        })
          .catch((error) => console.log('Error sharing', error));
      }
    },
    rewind: function() {
      if(this.$refs.audio) {
        var interval = this.settings ? this.settings.interval ? parseInt(this.settings.interval) : 15 : 15
        this.$refs.audio.currentTime -= interval
        this.updateTime()
      }
    },
    forward: function() {
      if(this.$refs.audio) {
        var interval = this.settings ? this.settings.interval ? parseInt(this.settings.interval) : 15 : 15
        this.$refs.audio.currentTime += interval
        this.updateTime()
      }
    },
    setTime: function(time) {
      time = parseInt(time)
      if(this.$refs.audio && typeof time == 'number') {
        this.$refs.audio.currentTime = time
        this.updateTime()
        this.updateDuration()
      }
    },
    scrub: function(event) {
      if (this.seeking) {
        var min = this.$refs.progress.getBoundingClientRect().left
        var max = this.$refs.progress.getBoundingClientRect().right
        var val = event.clientX ? event.clientX : event.touches[0].clientX
        var pos = (val-min)/(max-min)
        this.$refs.audio.currentTime = pos * this.duration
        this.updateTime()
      }
    },
    mobileScrub: function(event) {
      if (this.seeking) {
        var min = this.$refs.mobileProgress.getBoundingClientRect().left
        var max = this.$refs.mobileProgress.getBoundingClientRect().right
        var val = event.clientX ? event.clientX : event.touches[0].clientX
        var pos = (val-min)/(max-min)
        this.$refs.audio.currentTime = pos * this.duration
        this.updateTime()
      }
    },
    changeVolume: function(event) {
      if(this.seeking){
        if (this.$refs.audio) {
          var min = this.$refs.volume.getBoundingClientRect().left
          var max = this.$refs.volume.getBoundingClientRect().right
          var val = event.clientX
          var pos = (val-min)/(max-min)
          this.$refs.audio.volume = pos
          this.volume = pos
          this.$parent.setCookie('playerVolume', pos, 30)
          this.volumeBeforeMute = this.volume
        }
      }
    },
    tickVolume: function(val) {
      if (this.$refs.audio) {
        var pos = Number(this.volume) + val
        if (pos > 1){pos = 1}
        if (pos < 0){pos = 0}
        this.$refs.audio.volume = pos
        this.volume = pos
        this.$parent.setCookie('playerVolume', pos, 30)
        this.volumeBeforeMute = this.volume
      }
    },
    mute: function() {
      if (this.$refs.audio) {
        if (this.volumeBeforeMute==this.volume) {
          var pos = 0
        } else {
          var pos = this.volumeBeforeMute
        }
        this.$refs.audio.volume = pos
        this.volume = pos 
      }
    },
    updateTime: function() {
      if (this.session && this.$refs.audio) {
        if (this.time) {
          if (Math.abs(this.$refs.audio.currentTime - this.time)==0 && !this.paused) {
            this.failedPlays++
          } else {
            this.failedPlays = 0;
            if (this.errorMessage == 2 && !this.paused) {
              this.errorMessage = false
            }
          }
          if (this.failedPlays>=10) {
            this.errorMessage = 2
            if (this.failedPlays + 40 % 50 == 0) { // every 50 fails from the first 10, try to reload audio
              this.reloadAudio()
            }
          }
        }

        if (this.failedPlays<1) { // only update time if we have no failed plays
          if (this.$refs.audio.ended && !this.paused) {
            this.onEnd()
          }
          if (this.$refs.audio) {
            this.time = this.$refs.audio.currentTime
            this.paused = this.$refs.audio.paused
          }
          if (this.updateDurationOnNextTimeUpdate) {
            this.updateDuration()
          }
          this.$emit('updateListen', this.player.episode, this.time)
        }
      }
    },
    onEnd: function() {
      this.next()
    },
    next: function() {
      if (this.queue) {
        if (this.queue.length>0) {
          this.player.episode = this.queue.shift()
          this.$nextTick(()=>{
            this.episodeChanged(true)
          })
        }
      }
    },
    episodeChanged: function(forcePlay=false) {
      //#TODO: Get playstate of episode from API and set time to that
      this.setTime(0)
      this.updateQueue()
      if(forcePlay) {
        this.play(true)
      }
      this.updateMediaSession()
    },
    setQueue: function(queue, noUpdate=false) {
      this.queue = queue
      if (!noUpdate) {
        this.updateQueue()
      }
    },
    addToQueue: function(episode) {
      if (!this.inQueue(episode)) {
        this.queue.push(episode)
        this.updateQueue()
        this.$parent.message('Episode tilføjet til køen', 'green')
      } else {
        for (let i = 0; i < this.queue.length; i++) {
          if (episode.id == this.queue[i].id) {
            this.removeFromQueue(i)
            this.$parent.message('Episode fjernet fra køen', 'yellow')
          }
        }
      }
    },
    clearQueue: function() {
      this.queue = []
      this.updateQueue()
      this.$parent.message('Køen er blevet ryddet', 'yellow')
    },
    removeFromQueue: function(index) {
      this.queue.splice(index, 1)
      this.updateQueue()
    },
    updateQueue: function() {
      var epList = []
      for (let i = 0; i < this.queue.length; i++) {
        epList.push(this.queue[i].id)
      }
      axios.post(process.env.VUE_APP_API+'/queue/'+this.session, {
        queue: epList
      })
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      });
    },
    inQueue: function(episode) { // work around because queue.includes wouldn't work when switching views
      if (this.queue.length<1) {
        return false
      } else {
        for (var i = 0; i < this.queue.length; i++) {
          if (this.queue[i].id == episode.id) {
            return true
          }
        }
      }
    },
    updateDuration: function() {
      if (this.$refs.audio) {
        if (!isNaN(this.$refs.audio.duration)) {
          this.duration = this.$refs.audio.duration
        } else {
          this.duration = this.player.episode.duration
          this.updateDurationOnNextTimeUpdate = true
        }
      }
    },
    updateMediaSession: function() {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: this.player.episode.title,
          artist: this.player.episode.podcast.name,
          artwork: [
            { src: this.directusAsset(this.player.episode.podcast.cover_144),   sizes: '144x144',   type: 'image/png' },
            { src: this.directusAsset(this.player.episode.podcast.cover_1400),   sizes: '1400x1400',   type: 'image/png' },
            { src: this.directusAsset(this.player.episode.podcast.cover),  type: 'image/png' },
          ]
        })
        navigator.mediaSession.playbackState = this.paused ? 'paused' : 'playing'
        if (this.time && this.duration && this.playbackRate) {
          navigator.mediaSession.setPositionState({
            duration: this.duration,
            playbackRate: this.playbackRate,
            position: this.time
          })
        }
      }
    },
    updateRoute: function() {
      this.showMobilePlayer = false
    },
    updatePlaystate: function(force=false) {
      if (Math.abs(this.time - this.lastTime) >= 10 && !this.paused || force) { // if more than 10 sec dif and not paused
        axios.post(process.env.VUE_APP_API+'/playstate/'+this.session, {
          playstate: {
            episode: this.player.episode.id,
            time: this.time
          }
        })
        .then(response => {
            if (this.errorMessage == 1) {
              this.errorMessage = false
            }
          this.errorMessage = false
        })
        .catch(error => {
          if (!this.errorMessage) {
            this.errorMessage = 1
          }
          console.log(error)
        });
        this.lastTime = this.time
      }
    },
    errorMessageString: function() {
      switch (this.errorMessage) {
        case 1:
          return 'Kan ikke opdatere afspilningsstatus'

        case 2:
          return 'Kan ikke afspille lydfilen'
      
        default:
          break;
      }
    }
  },
  mounted: function() {
    this.updateDuration()
    if (this.$parent.getCookie('playerVolume')) {
      this.volume = this.$parent.getCookie('playerVolume')
      this.volumeBeforeMute = this.$parent.getCookie('playerVolume')
    }
    if (this.$parent.getCookie('playerPlaybackRate')) {
      this.playbackRate = this.$parent.getCookie('playerPlaybackRate')
    }
    setInterval(this.updateTime, 500)
    setInterval(this.updatePlaystate, 15000)

    if ('mediaSession' in navigator) {
      navigator.mediaSession.setActionHandler('play', () => {
        this.play()
      })
      navigator.mediaSession.setActionHandler('pause', () => {
        this.play()
      })
      navigator.mediaSession.setActionHandler('seekbackward', () => {
        this.rewind()
      })
      navigator.mediaSession.setActionHandler('seekforward', () => {
        this.forward()
      })
    }
  },
  watch: {
    player: function(to, from) {
      if (from.episode) { // New episode started
        if (from.episode.id != to.episode.id) {
          this.episodeChanged()
          if (this.inQueue(to.episode)) {
            for (let i = 0; i < this.queue.length; i++) {
              if (this.player.episode.id == this.queue[i].id) {
                this.removeFromQueue(i)
              }
            }
          }
          this.updatePlaystate(true)
        }
      }
    }
  },
  computed: {
    audioSource: function() {
      if (this.player.episode.audio) {
        return "audio"
      } else if (this.player.episode.cdn_audio) {
        return "cdn"
      } else {
        // return "sc"
	return "cdn"
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';

.mobile-full-player, .player {
  user-select: none;
}

.mobile-full-player {
  position: fixed;
  z-index: 900;
  background-color: var(--player-color-mobile-a);
  backdrop-filter: blur(12px);
  width: 100%;
  height: calc(100% - #{$nav-height + 1});
  top: #{$nav-height + 1};
  transition: top .5s;
  overflow: hidden;
}

@supports not (backdrop-filter: blur(12px)) {
  .mobile-full-player {
    background-color: var(--player-color-mobile);
  }
}

.mobile-full-player.hidden {
  top: 100vh;
}

.mobile-full-player .cover {
  padding: 1rem 3rem;
}

.mobile-full-player .cover img {
  width: 100%;
  border-radius: 3px;
}

.mobile-full-player .info .title, .mobile-full-player .info .author {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-full-player .author, .mobile-full-player .title, .mobile-full-player .summary {
  padding: 0 1rem;
}

.mobile-full-player .summary {
  border-top: 1px solid var(--font-color-3);
}

.mobile-full-player .title {
  font-size: 1.4rem;
  font-weight: 700;
  padding-top: 1rem;
}

.mobile-full-player .inner {
  position: absolute;
  bottom: 1rem;
  width: 100%;
}

.mobile-full-player .controls .lower-buttons {
  font-size: 1.4rem;
  padding: 0 1rem 1rem 1rem;
}

.mobile-full-player .controls .lower-buttons .hide a {
  padding: 1rem;
}

.mobile-full-player .controls .lower-buttons .speed {
  position: absolute;
  text-align: right;
  right: 1rem;
  bottom: 1rem;
  border-radius: 3px;
  transition: background-color .25s;
}

.mobile-full-player .controls .lower-buttons .shownotes {
  padding-right: .5rem;
}

.mobile-full-player .controls .lower-buttons .speed.open .options {
  background-color: var(--player-color-mobile);
}

.mobile-full-player .controls .lower-buttons .speed, .mobile-full-player .controls .lower-buttons .hide {
  padding: .5rem 0;
}

.mobile-full-player .controls .lower-buttons .speed .opener {
  padding: 0 1.5rem;
}

.mobile-full-player .controls .lower-buttons .speed .options {
  transition: opacity .25s;
  font-size: 12px;
  margin-bottom: 1rem;
  padding: .5rem 0;
  border-radius: 3px;
  text-align: center;
  position: relative;
  right: 1rem;
}

.mobile-full-player .controls .lower-buttons .speed.closed .options {
  display: none;
  opacity: 0;
  pointer-events: none;
}

.mobile-full-player .controls .lower-buttons .speed .options a {
  display: block;
  padding: .66rem 1.5rem;
}

.mobile-full-player .controls .lower-buttons .speed .options a:hover {
  background-color: var(--font-color-3);
}

.mobile-full-player .controls .lower-buttons .speed .options a.activeSpeed {
  background-color: $green;
  color: var(--font-color);
}

.mobile-full-player .controls .buttons {
  text-align: center;
  font-size: 1.4rem;
  position: relative;
  top: .5rem;
}

@media(min-width: 440px) {
  .mobile-full-player .controls .buttons {
    position: absolute;
    top: inherit;
    bottom: .575rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .mobile-full-player .time {
    margin-bottom: 1rem;
  }
}

.mobile-full-player .controls .buttons .play, .mobile-full-player .controls .buttons .rewind, .mobile-full-player .controls .buttons .forward {
  padding: 0 .5rem;
}

.mobile-full-player .controls .buttons .play {
  font-size: 2.8rem;
}

.mobile-full-player .controls .buttons .rewind, .mobile-full-player .controls .buttons .forward {
  position: relative;
  top: -11px;
}

.mobile-full-player .time {
  padding: 1rem;
}

.mobile-full-player .time .progress {
  cursor: pointer;
  background-color: var(--font-color-3);
  height: 5px;
  width: 100%;
}

.mobile-full-player .time .progress, .mobile-full-player .time .progress .filled {
  border-radius: 99px;
}

.mobile-full-player .time .progress .filled {
  height: 100%;
  background-color: $green;
  transition: width .25s;
}

.mobile-full-player .time .progress .playhead {
  width: 9px;
  height: 9px;
  background-color: var(--font-color);
  position: relative;
  border-radius: 99px;
  top: -7px;
  transition: left .25s;
}

.mobile-full-player .stamps {
  color: var(--font-color-1);
  padding-bottom: .5rem;
}

.mobile-full-player .stamps .current, .mobile-full-player .stamps .max {
  display: inline-block;
  width: 50%;
  font-family: 'Fira Code', monospace;
  font-size: 14px;
}

.mobile-full-player .stamps .max {
  text-align: right;
}

.player {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--player-color-a);
  backdrop-filter: blur(12px);
  border-top: 1px solid var(--font-color-3);
  height: $player-height;
  z-index: 899;
}

@supports not (backdrop-filter: blur(12px)) {
  .player {
    background-color: var(--player-color);
  }
}

.player .icon {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 3px;
  overflow: hidden;
}

.player .icon img {
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

.player .icon .on-hover {
  position: absolute;
  top: 0;
  background: var(--player-color-mobile-a);
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  opacity: 0;
  transition: opacity .15s;
}

.player .icon:hover .on-hover {
  opacity: 1;
}

.player .icon img {
  transition: filter .15s;
}

.player .icon:hover img {
  filter: blur(2px);
}

.player .icon .on-hover ion-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  color: var(--font-color);
}

.player .icon, .player .textPortion {
  display: inline-block;
}

.player .textPortion {
  vertical-align: top;
  position: relative;
}

.player.no-cover .icon {
  width: 0;
  margin: 0;
}

.player .textPortion {
  top: 6px;
}

.player .icon {
  margin-right: 1rem;
}

.player .controls {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.player .controls button {
  font-size: 1.5rem;
  padding: 0 .5rem;
}

.player .info {
  position: absolute;
  left: 1rem;
  top: calc(50% + 2px);
  transform: translateY(-50%);
}

@media(min-width:720px) {
  .player .info {
    z-index: 13;
  }
}

@media(max-width:719px) {
  .player .info {
    width: 100%
  }
}

.player .info .textPortion {
  max-width: 500px;
}

.player .info .textPortion .author {
  max-width: 500px;
}

@media(max-width: 1919px) {
  .player .info .textPortion .author {
    max-width: 360px;
  }
}

@media(max-width: 1719px) {
  .player .info .textPortion .author {
    max-width: 260px;
  }
}

@media(max-width: 1449px) {
  .player .info .textPortion {
    max-width: 400px;
  }
  .player .info .textPortion .author {
    max-width: 180px;
  }
}

@media(max-width: 1149px) {
  .player .info .textPortion {
    max-width: 300px;
  }
  .player .info .textPortion .author {
    max-width: 150px;
  }
}

@media(max-width: 949px) {
  .player .info .textPortion {
    max-width: 200px;
  }
}

@media(max-width: 849px) {
  .player .info .textPortion .author {
    max-width: 200px;
  }
}

@media(max-width: 719px) {
  .player .info .textPortion {
    max-width: calc(100% - 9.5rem);
  }
  .player .info .textPortion .author {
    max-width: 100%;
  }
}

.player .info .title {
  font-weight: 700;
}

.player .info .textPortion .title, .player .info .textPortion .author {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.player .progress {
  cursor: pointer;
  width: 100%;
  max-width: 600px;
  height: 5px;
  background-color: var(--font-color-3);
  margin: 0 1rem;
  border-radius: 99px;
}

@media(max-width: 1279px) {
  .player .progress {
    max-width: 400px;
  }
}

@media(max-width: 1049px) {
  .player .progress {
    max-width: 300px;
  }
}

@media(max-width: 949px) {
  .player .progress {
    max-width: 200px;
  }
}

.player .progress .filled-wrapper {
  height: 100%;
  border-radius: 99px;
  overflow: hidden;
}

.player .progress .filled {
  height: 100%;
  background-color: var(--font-color-1);
  border-radius: 99px;
}

.player .progress .filled {
  transition: width .25s;
}

.player .progress .playhead {
  transition: left .25s;
}

.player .progress:hover .filled {
  background-color: var(--primary-color);
}

.player .playhead {
  position: relative;
  background-color: var(--font-color);
  border-radius: 99px;
  top: -7px;
  width: 9px;
  height: 9px;
}

.player .progress:not(:hover) .playhead {
  display: none;
}

.player .time .current, .player .time .progress, .player .time .max {
  vertical-align: middle;
  display: inline-block;
}

.player .time .current, .player .time .max {
  font-family: 'Fira Code', monospace;
  color: var(--font-color-2);
  font-size: 12px;
}

.player .buttons {
  position: relative;
  z-index: 12;
}

.player .speed {
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  top: 2px;
}

.player .speed .opener {
  padding-top: 1rem;
}

.player .speed .opener ion-icon {
  padding: .5rem;
}

.player .speed.closed .options {
  opacity: 0;
  pointer-events: none;
}

.player .speed.open {
  background-color: var(--player-color-mobile);
}

.player .speed .options {
  padding-top: .5rem;
  font-size: 12px;
}

.player .speed .options a {
  display: block;
  padding: .5rem 1rem;
}

.player .speed .options a:hover {
  background-color: var(--font-color-3);
}

.player .speed .options a.activeSpeed {
  background-color: var(--primary-color);
  color: var(--font-color);
}

.player .right-buttons {
  position: absolute;
  right: 0;
  bottom: 2px;
  z-index: 14;
  margin-right: .5rem;
}

.player .right-buttons ion-icon {
  font-size: 1.2rem;
}

.player .volume-container, .player .speed {
  display: inline-block;
  margin-right: .5rem;
}

.player .volume-container {
  position: relative;
  bottom: 12px;
}

.player .volume-container .mute {
  position: relative;
  right: 12px;
  top: 4px;
} 

.player .volume {
  cursor: pointer;
  position: relative;
  bottom: 3px;
  display: inline-block;
  background-color: var(--font-color-3);
  height: 5px;
  width: 100px;
  border-radius: 99px;
  overflow: hidden;
}

.player .volume .filled {
  transition: width .25s;
  background-color: var(--font-color-1);
  border-radius: 99px;
  width: 0;
  height: 100%;
}

.player .volume:hover .filled {
  background-color: var(--primary-color);
}

@media(max-width: 849px) {
  .player .controls {
    height: 100%;
  }
  .player .time {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 100%;
  }
  .player .time .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0;
    max-width: 100%;
    border-radius: 0;
  }
  .player .time .progress .filled-wrapper {
    border-radius: 0;
  }
  .player .time .progress .filled {
    border-radius: 0;
  }
  .player .time .current, .player .time .max {
    display: none;
  }
  .player .buttons {
    position: relative;
    top: 22px;
  }
  .player .right-buttons {
    bottom: 16px;
  }
  .player .playhead {
    display: none;
  }
  .player .info {
    top: calc(50%);
  }
}

@media(max-width: 719px) {
  .player .speed {
    display: none;
  }
  .player .volume-container {
    display: none;
  }
  .player .buttons .rewind, .player .buttons .forward {
    display: none;
  }
  .player .buttons {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
}

@media(min-width: 721px) {
  .mobile-full-player {
    display: none;
  }
}




.no-internet {
  width: 100vw;
  background-color: var(--red);
  display: block;
  position: absolute;
  padding: 0.25rem;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  bottom: $player-height + 1px;
}

.rewind ion-icon {
  transform: rotateY(180deg);
}
</style>
