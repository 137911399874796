<template>
  <div class="wrapper">

    <div class="update completelyhidden" :class="[dismissed ? 'dismissed' : null]">
      <div class="message">
        <p class="text">
          🚀 En ny opdatering er tilgængelig!<br>
          Genindlæs appen få at få adgang til nye spændende features.
        </p>
        <div class="buttons">
          <a href="javascript:void(0);" v-on:click="reload" class="button alt get pulse"><ion-icon name="cloud-download"></ion-icon> Opdater</a>
          <a href="javascript:void(0);" v-on:click="dismiss" class="plain close">Luk</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'UpdateMessage',
  data: function() {
    return {
      dismissed: true
    }
  },
  mounted: function() {
    setTimeout(()=>{
      this.dismissed = false
    },100)
  },
  methods: {
    reload: function() {
      navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING'});
      setTimeout(()=>{
        localStorage.clear()
        location.reload(true)
      }, 500)
    },
    dismiss: function() {
      this.dismissed = true
    }
  }
}
</script>

<style lang="scss" scoped>
.update {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 64px;
  background-color: var(--primary-color);
  box-shadow: 0 0 4rem var(--primary-color);
  overflow: hidden;
  transition: max-height .66s ease-in-out;
  max-height: 172px;
  animation: pulseShadow 2s infinite ease-in-out;
}

@keyframes pulseShadow {
  0% {
    box-shadow: 0 0 4rem var(--primary-color);
  }
  50% {
    box-shadow: 0 0 6rem var(--primary-color);
  }
  100% {
    box-shadow: 0 0 4rem var(--primary-color);
  }
}

.bounce {
  display: inline-block;
  animation: bounce 2s infinite ease-in-out;
}

@keyframes bounce {
  0% {
    transform: translateY(-.15rem);
  }
  50% {
    transform: translateY(.15rem);
  }
  100% {
    transform: translateY(-.15rem);
  }
}

.pulse {
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 2rem var(--font-color-1);
  }
  50% {
    box-shadow: 0 0 4rem .5rem var(--font-color);
  }
  100% {
    box-shadow: 0 0 2rem var(--font-color-1);
  }
}

.text {
  line-height: 1.6875;
}

.update.dismissed {
  max-height: 0;
  border-color: transparent;
}

.message {
  margin: auto;
  padding: 0 1rem 1rem 1rem;
  max-width: 720px;
}

.buttons {
  text-align: right;
}

.close {
  margin-left: 1rem;
}

.completelyhidden {
  display: none;
}
</style>