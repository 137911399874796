<template>
  <div id="nav" :class="player.episode ? 'player' : 'no-player'" v-click-outside="hide">
    <div class="back content" v-if="!session && !slug.startsWith('/login') && !slug.startsWith('/register')">
      <router-link to="/login" class="alt"><ion-icon name="arrow-back"></ion-icon> Log Ind</router-link>
    </div>
    <div class="nav" :class="session ? 'session' : 'no-session'">
      <router-link v-on:click.native="navclick" to="/feed"><div class="c"><img src="@/assets/icons/M.svg" alt=""></div></router-link>
      <router-link v-on:click.native="navclick" to="/personal"><div class="c"><ion-icon name="person"></ion-icon></div></router-link>
      <a class="open-more" href="javascript:void(0)" v-on:click="toggleMore"><div class="c"><ion-icon name="menu"></ion-icon></div></a>
    </div>
    <div class="more" :class="[ session ? 'session' : 'no-session', more ? 'show-more' : 'no-more' ]">
      <router-link class="alt" to="/search">Søg</router-link>
      <router-link class="alt" to="/queue">Afspilningskø</router-link>
      <router-link class="alt" to="/podcast">Podcasts</router-link>
      <router-link class="alt" to="/news">Nyheder</router-link>
      <router-link class="alt" to="/profile">Min Profil</router-link>
      <router-link class="alt" to="/settings">Indstillinger</router-link>
      <div class="spacer"/>
      <router-link class="alt" to="/about">Om</router-link>
      <router-link class="alt" to="/privacy">Privatlivspolitik</router-link>
      <router-link class="alt" to="/terms-and-conditions">Vilkår &amp; Betingelser</router-link>
      <div class="spacer"/>
      <a href="javascript:void(0)" v-on:click="destroySession()" class="action yellow">Log Ud</a>
      <div class="git-hash monospace muted">
        <span class="tooltip" :data-tooltip="'#'+gitHash">v{{version}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'Nav',
  props: {
    session: String,
    player: Object
  },
  directives: {
    ClickOutside
  },
  data: function() {
    return {
      gitHash: process.env.VUE_APP_GIT_HASH,
      version: process.env.VUE_APP_VERSION,
      more: false,
      slug: window.location.href.replace(window.location.origin,'')
    }
  },
  methods: {
    destroySession: function() {
      this.$emit('destroySession')
    },
    toggleMore: function() {
      this.more=!this.more
      this.$emit('hideMobilePlayer')
    },
    hide: function() {
      this.more=false
    },
    updateRoute: function() {
      this.hide()
    },
    navclick: function() {
      document.getElementById('app').scrollTo(0,0);
    }
  },
  watch: {
    $route: function() {
      this.slug = window.location.href.replace(window.location.origin,'')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';

.back {
  margin-top: 2rem;
}

.nav {
  width: 100vw;
  background-color: var(--bg-color-1);
  backdrop-filter: blur(12px);
  position: fixed;
  top: 0;
  border-bottom: 1px solid var(--font-color-3);
  text-align: center;
  z-index: 999;
  transition: opacity .5s;
  height: $nav-height;
  overflow: hidden;
}

.spacer {
  display: block;
  padding-top: 1rem;
  border-bottom: 1px solid var(--font-color-3);
  width: 100%;
}

@supports not (backdrop-filter: blur(12px)) {
  .nav {
    background-color: var(--bg-color);
  }
}

.nav a {
  position: relative;
  font-size: 24px;
  display: inline-block;
  padding: 0 2.2rem;
  height: 100%;
  color: var(--font-color);
  position: relative;
}

.nav a .c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .4;
  transition-duration: .15s;
  transition-property: color, opacity, background;
}

.nav a:hover .c {
  opacity: .67;
}

.nav a.router-link-exact-active {
  background-color: var(--font-color-3);
}

.nav a.router-link-exact-active .c {
  opacity: 1;
}

.nav a:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 0;
  background-color: var(--font-color);
  transition-duration: .15s;
  transition-property: height;
}

.nav a.router-link-exact-active:after {
  height: 4px;
}

.nav img {
  position: relative;
  top: 2px;
  width: 24px;
  height: 24px;
}

#app.light .nav img {
  filter: invert(1);
}

@media (prefers-color-scheme: light) {
  #app:not(.dark) .nav img {
    filter: invert(1);
  }
}

.no-session {
  pointer-events: none;
  opacity: 0;
}

#nav {
  position: relative;
}

#nav .nav a.open-more {
  position: absolute;
  right: 0;
}

.more {
  top: 0;
  margin: 0;
  height: calc(100% - #{$player-height + $nav-height});
  z-index: 14;
  position: fixed;
  background: var(--bg-color-1);
  backdrop-filter: blur(12px);
  margin-top: $nav-height + 1px;
  width: 100%;
  max-width: 720px;
  transition: right .5s, opacity .5s;
}

@supports not (backdrop-filter: blur(12px)) {
  .more {
    background-color: var(--bg-color);
  }
}

.no-player .more {
  height: calc(100% - #{$nav-height});
}

.more a {
  display: block;
  padding: 1rem;
  border-bottom: 1px solid var(--font-color-3);
}

.more a:hover {
  background-color: var(--font-color-4);
}

.more .git-hash {
  text-align: right;
  padding: 1rem
}

.more {
  right: 0;
  transition-property: right;
}

.no-more {
  right: -100%;
  opacity: 0;
  pointer-events: none;
  transition-property: right, opacity;
}

@media(min-width: 721px) {
  .more {
    left: auto;
    transform: none;
    border-left: 1px solid var(--font-color-3);
    max-width: $more-width;
    text-align: right;
  }
  .no-more {
    right: -($more-width + 1px);
  }
}
</style>