<template>
  <div class="home">
    <Ripple />
  </div>
</template>

<script>
import Ripple from '@/components/Ripple.vue'

export default {
  name: 'Loading',
  components: {
    Ripple
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
}
</style>